<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h2 class="curr_time">
          {{ curr_date }}
          <br />{{ curr_weekDay }}
          <span>{{ curr_time }}</span>
        </h2>
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button @click="fullScreen = !fullScreen" class="btn_sub1">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="6">
                작업지시현황 <span>조회시간 : {{ str_time }}</span>
              </th>
            </tr>
            <tr class="d_day">
              <th>작업순서</th>
              <th>거래처명</th>
              <th>품목</th>
              <th>중량</th>
              <th>수량</th>
              <th>현재상태</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(lot, index) in lots"
              :key="lot.id"
              :class="{
                waiting: lot.lot_type_id == 1,
                proceeding: lot.lot_type_id == 3,
              }"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ findInfoFromId(companys, lot.company_id).name }}</td>
              <td>{{ findInfoFromId(products, lot.product_id).name }}</td>
              <td>{{ findInfoFromId(products, lot.product_id).standard }}</td>
              <td>
                {{ $makeComma(lot.quantity) }}({{
                  findInfoFromId(
                    units,
                    findInfoFromId(products, lot.product_id).stock_unit_id,
                  ).name
                }})
              </td>
              <td>
                {{ findInfoFromId(lot_types, lot.lot_type_id).name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import { yyyymmdd, yyyy년MM월dd일, HH시mm분, X요일 } from '@/utils/func';
import { format년월일요일시분초 } from '@/utils/filters';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timeCount: 30,
      timerMax: 30,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      start_date: null,
      end_date: null,
      timeNowStr: null,
      timeNowInterval: null,

      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProduct',
      units: 'getUnitCodes',
      companys: 'getCompany',
      lots_src: 'getLot',
      lot_types: 'getLotType',
    }),
    lots() {
      if (this.lots_src.length < 1) {
        return [];
      } else {
        let lotSrcTemp = this.lodash
          .clonedeep(this.lots_src)
          .filter(
            x =>
              !this.findInfoFromId(this.products, x.product_id).oem_product_yn,
          );
        return lotSrcTemp.sort((a, b) => a.id - b.id);
      }
    },
  },
  methods: {
    async refreshData() {
      this.str_time = format년월일요일시분초(new Date());
      this.FETCH_LOT_DAY();
    },
    FETCH_LOT_DAY() {
      let end_date = new Date();
      this.$store
        .dispatch('FETCH_LOT_DAY', {
          start: yyyymmdd(new Date(end_date)),
          end: yyyymmdd(new Date(end_date)),
        })
        .then(response => {
          console.log(response);
        })
        .catch(() => {
          this.openOneButtonModal(
            '조회 중 오류',
            '작업지시 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
  },
  destroyed() {
    clearInterval(this.timeInterval);
    clearInterval(this.timeNowInterval);
  },
  async created() {
    this.timeNowStr = new Date();
    this.timeNowInterval = setInterval(() => {
      const newDate = new Date();
      // this.str_time = format년월일요일시분초(newDate);
      this.curr_date = yyyy년MM월dd일(newDate);
      this.curr_time = HH시mm분(newDate);
      this.curr_weekDay = X요일(newDate);
    }, 1000);
    if (this.lot_types.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업지시 상태');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '납품처');
    }
    await this.FETCH_LOT_DAY();

    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        this.FETCH_LOT_DAY();
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);

    this.str_time = format년월일요일시분초(new Date());
    this.curr_date = yyyy년MM월dd일(new Date());
    this.curr_time = HH시mm분(new Date());
    this.curr_weekDay = X요일(new Date());
  },
};
</script>

<style lang="scss" scoped></style>
