<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>재고현황</h2>
          <span>조회시간 : {{ str_time }}</span>
        </div>
        <div class="monitor_body" v-for="(n, index) in 2" :key="index">
          <div class="day_card" v-show="index == 0">
            <h2 class="curr_time">
              {{ curr_date }}
              <br />{{ curr_weekDay }}
              <span>{{ curr_time }}</span>
            </h2>
          </div>
          <div
            class="monitor_card"
            :class="{ white_card: index == 1 }"
            v-for="item in index == 0
              ? materialTotalStock.filter(
                  x =>
                    findInfoFromId(materials, x.material_id).resource_type_id !=
                    3,
                )
              : materialTotalStock.filter(
                  x =>
                    findInfoFromId(materials, x.material_id).resource_type_id ==
                    3,
                )"
            :key="item.id"
          >
            <h6>
              {{ findInfoFromId(materials, item.material_id).name
              }}{{
                findInfoFromId(materials, item.material_id).standard
                  ? ' - ' + findInfoFromId(materials, item.material_id).standard
                  : ''
              }}
            </h6>
            <div>
              <p>
                {{ item.stock_quantity }}
                <span>
                  {{
                    findInfoFromId(
                      units,
                      findInfoFromId(materials, item.material_id).using_unit_id,
                    ).name
                  }}</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      materialTotalStock: 'getMaterialTotalStock',
      productTotalStock: 'getProductTotalStock',
      products: 'getProduct',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      resource_types: 'getResourceType',
    }),
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    },
  },
  async created() {
    if (this.materials.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.products.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_UNIT', '단위');
    }
    if (this.resource_types.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_RESOURCE_TYPE', '자원 구분');
    }
    await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 1) {
        await this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount--;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount--;
    }, 1000);

    this.currentTimeInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>
<style lang="scss" scoped></style>
