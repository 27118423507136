<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button type="button" class="btn_sub1 refresh_btn" @click="refreshData">
          재조회
        </button>
        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>
      <div class="monitor_wrap">
        <div class="monitor_head">
          <h2>출고현황</h2>
          <span>조회시간 : {{ str_time }}</span>
        </div>
        <swiper
          ref="mySwiperRef"
          class="swiper-container"
          :options="swiperOption"
        >
          <swiper-slide
            class="monitor_body"
            v-for="(items, index) in filteredProductGroupList"
            :key="index"
          >
            <div class="day_card">
              <h2 class="curr_time">
                {{ curr_date }}
                <br />{{ curr_weekDay }}
                <span>{{ curr_time }}</span>
              </h2>
            </div>
            <div class="monitor_card" v-for="item in items" :key="item.id">
              <h6>
                {{ item.name }} -
                {{ item.standard }}
              </h6>
              <div>
                <p>
                  {{ $makeComma(item.day_output_quantity) }}
                  <span>{{
                    findInfoFromId(
                      units,
                      findInfoFromId(products, item.id).stock_unit_id,
                    ).name
                  }}</span>
                </p>
              </div>
              <ul>
                <li>
                  <span>W</span>{{ $makeComma(item.week_output_quantity) }}
                </li>
                <li>
                  <span>M</span>{{ $makeComma(item.month_output_quantity) }}
                </li>
              </ul>
            </div>
          </swiper-slide>
        </swiper>
        <div class="slide_btn_wrap">
          <button type="button" class="button-prev">
            <i class="fa fa-angle-left"></i>
          </button>
          <button
            type="button"
            class="button-play-stop"
            :class="{ active: playStop }"
            @click.prevent="autoPlay()"
          ></button>
          <button type="button" class="button-next">
            <i class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SWIPER_MIXIN from '@/mixins/status_board_swiper';
import {
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
  HH시mm분,
  X요일,
} from '@/utils/func';

export default {
  mixins: [FETCH_MIXIN, SWIPER_MIXIN],
  data() {
    return {
      fullScreen: false,
      curr_date: yyyy년MM월dd일(new Date()),
      curr_time: HH시mm분(new Date()),
      curr_weekDay: X요일(new Date()),
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
      delay: 10000,
    };
  },
  computed: {
    ...mapGetters({
      productStatusGroupList:
        'getProductStockStatusGroupListFromProductStockStatus',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      sales: 'getSalesFromOutputRequest',
      tabIndex: 'getOpenTabIndexFromStatusMonitorPage',
    }),
    filteredProductGroupList() {
      if (this.productStatusGroupList != undefined) {
        let clone = this.lodash
          .clonedeep(this.productStatusGroupList)
          .sort((a, b) => a.id - b.id);
        let arr = [];
        clone.forEach((element, index) => {
          if (index % 12 == 0) {
            arr.push(clone.slice(index, index + 12));
          }
        });
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    async refreshData() {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS_GROUP', '제품 재고');
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.productStatusGroupList.length < 1) {
      await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS_GROUP', '제품 재고');
    }
    this.timerCount = this.timerMax;

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 2) {
        await this.FETCH_NO_SPINNER('FETCH_PRODUCT_STATUS_GROUP', '제품 재고');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount--;
      }, 1000);
    }, this.timerMax * 10000);

    this.timeInterval = setInterval(() => {
      this.timerCount--;
    }, 1000);

    this.currentTimeInterval = setInterval(() => {
      this.curr_date = yyyy년MM월dd일(new Date());
      this.curr_time = HH시mm분(new Date());
      this.curr_weekDay = X요일(new Date());
    }, 1000);
    this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
  },
};
</script>
<style lang="scss" scoped></style>
