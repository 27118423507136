var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    status_board_card: _vm.tabIndex != 3,
    stock_status: _vm.tabIndex == 1,
    request_status: _vm.tabIndex == 2,
    status_board_saengum: _vm.tabIndex == 3,
  },attrs:{"id":"contents"}},[_c('div',{staticClass:"contents_head"},[_c('h2',[_vm._v(_vm._s(_vm.$getPageTitle(_vm.$route.path)))]),(_vm.$screen.width < 1280)?_c('div',{staticClass:"aside"},[_c('aside-selectric',{attrs:{"id":'tabletAsideSelectric',"watch":_vm.tabIndex,"commit":'setOpenTabIndexToStatusMonitorPage',"options":[
          { title: '생산현황', name: 'product_status' },
          { title: '재고현황', name: 'stock_status' },
          { title: '출고현황', name: 'request_order_status' },
          { title: '작업지시현황', name: 'work_order_status' } ]}})],1):_vm._e(),_c('div',{staticClass:"management_btn_group"},[_c('div',[_c('span',{staticClass:"add_favorite",class:{ on: _vm.isFavorOn },on:{"click":_vm.AddFavorite}},[_vm._v("즐겨찾기 추가")]),_c('button',{staticClass:"btn_admin",on:{"click":function($event){return _vm.CloseThisPage()}}},[_vm._v("닫기")])])])]),_c('div',{staticClass:"section"},[(_vm.$screen.width >= 1280)?_c('div',{staticClass:"aside"},[_c('ul',[_c('li',{class:{ active: _vm.tabIndex == 0 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(0)}}},[_vm._v("생산현황")])]),_c('li',{class:{ active: _vm.tabIndex == 1 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(1)}}},[_vm._v("재고현황")])]),_c('li',{class:{ active: _vm.tabIndex == 2 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(2)}}},[_vm._v("출고현황")])]),_c('li',{class:{ active: _vm.tabIndex == 3 }},[_c('a',{on:{"click":function($event){return _vm.SetOpenTabIndex(3)}}},[_vm._v("작업지시현황")])])])]):_vm._e(),(_vm.tabIndex == 0)?_c('production-status-form'):_vm._e(),(_vm.tabIndex == 1)?_c('stock-status-form'):_vm._e(),(_vm.tabIndex == 2)?_c('request-status-form'):_vm._e(),(_vm.tabIndex == 3)?_c('work-order-status-form'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }